import { Box, Container, Flex, HStack } from "@chakra-ui/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import SearchInput from "./components/SearchInput";
import { SearchResults } from "./components/SearchResults";
import { useSearch } from "./providers/SearchProvider";
import { EmbeddedFilters } from "../filters/components/EmbeddedFilters";
import { TopSearches } from "@/search/components/topSearch/TopSearches";
import { useIsExtraLargeScreen } from "@/ui/hooks/useIsExtraLargeScreen";
export default function SearchScreen() {
  const {
    t
  } = useTranslation("titles");
  const router = useRouter();
  const {
    query
  } = useSearch();
  const isExtraLargeScreen = useIsExtraLargeScreen();
  const layoutWidth = isExtraLargeScreen && router.route.includes("/search/results") ? "120ch" : undefined;
  useEffect(() => {
    if (query) {
      router.replace(`/search/results?query=${query}`);
    }
  }, [query]);
  const handleGoBack = () => {
    router.back();
  };
  const renderContent = () => {
    if (router.route.includes("/search/results")) {
      return <Container maxWidth={layoutWidth} width="100%">
          <HStack alignItems="flex-start" gap={10}>
            {isExtraLargeScreen ? <Box width="385px" flexBasis="385px" flexShrink={0}>
                <EmbeddedFilters />
              </Box> : null}
            <SearchResults />
          </HStack>
        </Container>;
    } else {
      return <Box flexGrow={1} className="scrollbar" mt={12}>
          <TopSearches />
        </Box>;
    }
  };
  return <Flex flexDirection="column" minH="100vh" data-sentry-element="Flex" data-sentry-component="SearchScreen" data-sentry-source-file="SearchScreen.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="SearchScreen.tsx">
        <title>{`Snowday | ${t("page-titles.results")}`}</title>
      </Head>
      <Box flexGrow={1} data-sentry-element="Box" data-sentry-source-file="SearchScreen.tsx">
        <Container px={{
        base: 4,
        lg: 4
      }} my={{
        base: 3,
        lg: 6
      }} width={layoutWidth} maxWidth={layoutWidth} data-sentry-element="Container" data-sentry-source-file="SearchScreen.tsx">
          <SearchInput goBack={true} onClick={handleGoBack} icon="ArrowLeft" data-sentry-element="SearchInput" data-sentry-source-file="SearchScreen.tsx" />
        </Container>
        {renderContent()}
      </Box>
    </Flex>;
}