import { Button, Checkbox, Flex, Input, InputGroup, InputLeftElement, InputRightElement, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { financialAccessibilityOptions } from "../../options";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { FinancialAccessibility } from "@/generated/graphql";
import MoneybagIcons from "@/icons/Moneybag";
import SnowdayIcons from "@/icons/SnowdayIcons";
const FinancialAccessibilityFilter = () => {
  const {
    editedFilters,
    onAdd,
    onRemove
  } = useFilter();
  const {
    onToggle,
    isOpen
  } = useDisclosure();
  const onChange = (value: FinancialAccessibility, checked: boolean) => {
    checked ? onAdd(FiltersType.FINANCIAL_ACCESSIBILITY, value) : onRemove(FiltersType.FINANCIAL_ACCESSIBILITY, value);
  };
  const toggleAll = (checked: boolean) => {
    financialAccessibilityOptions.forEach(({
      value
    }) => checked ? onAdd(FiltersType.FINANCIAL_ACCESSIBILITY, value) : onRemove(FiltersType.FINANCIAL_ACCESSIBILITY, value));
  };
  const inputText = () => {
    return financialAccessibilityOptions.filter(({
      value
    }) => editedFilters[FiltersType.FINANCIAL_ACCESSIBILITY]?.includes(value)).map(({
      label
    }) => label).join(", ");
  };
  return <>
      <InputGroup mb={4} cursor="pointer" onClick={onToggle} bg="white" data-sentry-element="InputGroup" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
        <InputLeftElement children={<MoneybagIcons color="snow.blue-dark" variant={FinancialAccessibility.None} boxSize={6} />} data-sentry-element="InputLeftElement" data-sentry-source-file="FinancialAccessibilityFilter.tsx" />
        <Input value={inputText()} readOnly pointerEvents="none" placeholder="Select ratings..." data-sentry-element="Input" data-sentry-source-file="FinancialAccessibilityFilter.tsx" />
        <InputRightElement bg="snow.blue-light" borderRightRadius={8} children={<SnowdayIcons color="snow.blue-dark" name="Dropdown" boxSize={6} transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"} transition="all 0.15s ease-in-out" />} data-sentry-element="InputRightElement" data-sentry-source-file="FinancialAccessibilityFilter.tsx" />
      </InputGroup>
      <Flex mt={-4} borderBottomRadius={8} maxH={isOpen ? 340 : 0} h="fit-content" flexDirection="column" w="100%" transition="all 0.3s ease" overflow="hidden" boxShadow="0px 1px 2px rgba(52, 67, 114, 0.08), 0px 2px 4px rgba(52, 67, 114, 0.2)" data-sentry-element="Flex" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
        <Flex px={3} py={4} flexDirection="column" gap={3} data-sentry-element="Flex" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
          <Checkbox variant="filter" size="lg" value="all" onChange={e => toggleAll(e.target.checked)} isChecked={editedFilters[FiltersType.FINANCIAL_ACCESSIBILITY]?.length === financialAccessibilityOptions.length} data-sentry-element="Checkbox" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
            Select All
          </Checkbox>
          <Stack pl={5} spacing={3} data-sentry-element="Stack" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
            {financialAccessibilityOptions.map(option => <Checkbox key={"option" + option.value} value={option.value} variant="filter" size="lg" onChange={e => onChange(option.value, e.target.checked)} isChecked={editedFilters[FiltersType.FINANCIAL_ACCESSIBILITY]?.includes(option.value) ?? false}>
                <Flex w="100%" justifyContent="space-between" alignItems="center" gap={2}>
                  <Text variant="smallBody">{option.label}</Text>
                  <Text variant="smallBody" textAlign="right">
                    {option.description}
                  </Text>
                </Flex>
              </Checkbox>)}
          </Stack>
        </Flex>
        <Flex w="100%" justifyContent="center" pb={3} data-sentry-element="Flex" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
          <Button variant="link" color="snow.blue" onClick={() => toggleAll(false)} data-sentry-element="Button" data-sentry-source-file="FinancialAccessibilityFilter.tsx">
            Clear All
          </Button>
        </Flex>
      </Flex>
    </>;
};
export default FinancialAccessibilityFilter;