import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text } from "@chakra-ui/react";
import { FC } from "react";
interface HelperModal {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}
const HelperModal: FC<HelperModal> = ({
  open,
  onClose,
  title,
  children
}) => {
  return <Modal isOpen={open} onClose={onClose} data-sentry-element="Modal" data-sentry-component="HelperModal" data-sentry-source-file="HelperModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="HelperModal.tsx" />
      <ModalContent height={{
      base: "520px",
      md: "560px",
      lg: "480px",
      xl: "520px"
    }} py={10} data-sentry-element="ModalContent" data-sentry-source-file="HelperModal.tsx">
        <ModalHeader paddingBottom={5} data-sentry-element="ModalHeader" data-sentry-source-file="HelperModal.tsx">
          <Text align="center" data-sentry-element="Text" data-sentry-source-file="HelperModal.tsx">{title}</Text>
        </ModalHeader>
        <ModalCloseButton data-sentry-element="ModalCloseButton" data-sentry-source-file="HelperModal.tsx" />
        <ModalBody overflowY="auto" data-sentry-element="ModalBody" data-sentry-source-file="HelperModal.tsx">{children}</ModalBody>
      </ModalContent>
    </Modal>;
};
export default HelperModal;