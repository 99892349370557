import { Button, ButtonGroup, Drawer, DrawerContent, DrawerOverlay, Flex, Heading, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { Filters } from "./Filters";
import { FilterState } from "../state/reducer";
import { useFilter } from "@/filters/providers/FiltersProvider";
import FilterIcon from "@/icons/Filter";
import SnowdayIcons from "@/icons/SnowdayIcons";
interface FilterComponentProps {
  onChange?: (newFilters: FilterState) => void;
}
export default function FilterButton({
  onChange
}: FilterComponentProps) {
  const {
    count,
    editedFilters: filters,
    hasUnappliedFilters: isChanged,
    onCancel,
    onApply,
    onClear
  } = useFilter();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const handleClear = () => {
    onClear();
    onClose();
  };
  const handleClose = () => {
    onCancel();
    onClose();
  };
  const handleApply = () => {
    onChange?.(filters);
    onApply();
    onClose();
  };
  return <>
      <ButtonGroup data-sentry-element="ButtonGroup" data-sentry-source-file="FilterButton.tsx">
        <Button variant="secondary" size="sm" aria-label="Search" pl={2} pr={3} py={0} color="snow.blue-light-medium" _hover={{
        bg: "snow.blue",
        color: "snow.blue-dark"
      }} onClick={onOpen} data-sentry-element="Button" data-sentry-source-file="FilterButton.tsx">
          <FilterIcon color="inherit" boxSize={4} mr={1} data-sentry-element="FilterIcon" data-sentry-source-file="FilterButton.tsx" />
          <Text color="inherit" variant="smallBody" data-sentry-element="Text" data-sentry-source-file="FilterButton.tsx">
            Filter
          </Text>
          {count > 0 && <Text ml={0.5} color="inherit" variant="smallBody">
              ({count})
            </Text>}
        </Button>
      </ButtonGroup>
      <Drawer onClose={handleClose} isOpen={isOpen} size={{
      base: "full",
      lg: "md"
    }} data-sentry-element="Drawer" data-sentry-source-file="FilterButton.tsx">
        <DrawerOverlay data-sentry-element="DrawerOverlay" data-sentry-source-file="FilterButton.tsx" />
        <DrawerContent py={3} px={2} maxHeight="100dvh" data-sentry-element="DrawerContent" data-sentry-source-file="FilterButton.tsx">
          <Flex flexGrow={0} justifyContent="space-between" alignItems="center" px={2} data-sentry-element="Flex" data-sentry-source-file="FilterButton.tsx">
            <IconButton minW={3} variant="unstyled" icon={<SnowdayIcons name="ArrowLeft" />} aria-label="close-filters" onClick={handleClose} data-sentry-element="IconButton" data-sentry-source-file="FilterButton.tsx" />
            <Heading variant="h2" data-sentry-element="Heading" data-sentry-source-file="FilterButton.tsx">Filters</Heading>
            <Button variant="link" color="snow.blue-light-medium" onClick={handleClear} data-sentry-element="Button" data-sentry-source-file="FilterButton.tsx">
              Reset Filters
            </Button>
          </Flex>
          <Filters data-sentry-element="Filters" data-sentry-source-file="FilterButton.tsx" />
          <Flex flexGrow={0} flexDirection="column" gap={4} py={5} px={2} borderTop="1px solid #2117281F" data-sentry-element="Flex" data-sentry-source-file="FilterButton.tsx">
            <Button variant="primary" py={3} onClick={handleApply} isDisabled={!isChanged} data-sentry-element="Button" data-sentry-source-file="FilterButton.tsx">
              Apply Filters
            </Button>
            <Button variant="link" color="snow.blue" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="FilterButton.tsx">
              Cancel
            </Button>
          </Flex>
        </DrawerContent>
      </Drawer>
    </>;
}