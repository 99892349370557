import { Box, Card, Grid, GridItem, VStack, Skeleton, SkeletonText, SkeletonCircle } from "@chakra-ui/react";
export default function LOResultCardSkeleton() {
  return <Card w="100%" padding={4} data-sentry-element="Card" data-sentry-component="LOResultCardSkeleton" data-sentry-source-file="LOResultCardSkeleton.tsx">
      <Grid gap="2" templateColumns="auto 1fr auto" data-sentry-element="Grid" data-sentry-source-file="LOResultCardSkeleton.tsx">
        <GridItem colSpan={1} rowSpan={4} data-sentry-element="GridItem" data-sentry-source-file="LOResultCardSkeleton.tsx">
          <Skeleton boxSize={{
          base: 16,
          md: 20
        }} rounded={4} data-sentry-element="Skeleton" data-sentry-source-file="LOResultCardSkeleton.tsx" />
        </GridItem>
        <GridItem data-sentry-element="GridItem" data-sentry-source-file="LOResultCardSkeleton.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="LOResultCardSkeleton.tsx">
            <SkeletonText noOfLines={10} data-sentry-element="SkeletonText" data-sentry-source-file="LOResultCardSkeleton.tsx" />
          </Box>
        </GridItem>
        <GridItem colStart={3} data-sentry-element="GridItem" data-sentry-source-file="LOResultCardSkeleton.tsx">
          <VStack data-sentry-element="VStack" data-sentry-source-file="LOResultCardSkeleton.tsx">
            <SkeletonCircle boxSize={6} data-sentry-element="SkeletonCircle" data-sentry-source-file="LOResultCardSkeleton.tsx" />
            <SkeletonCircle boxSize={6} data-sentry-element="SkeletonCircle" data-sentry-source-file="LOResultCardSkeleton.tsx" />
          </VStack>
        </GridItem>
      </Grid>
    </Card>;
}