import { Checkbox, InputGroup, Stack } from "@chakra-ui/react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { Grade } from "@/generated/graphql";
const options = [{
  value: Grade.Nine,
  label: "9"
}, {
  value: Grade.Ten,
  label: "10"
}, {
  value: Grade.Eleven,
  label: "11"
}, {
  value: Grade.Twelve,
  label: "12"
}];
const GradesFilter = () => {
  const {
    editedFilters,
    onToggle
  } = useFilter();
  const onChange = (value: Grade, _: boolean) => {
    onToggle(FiltersType.GRADES, value);
  };
  return <InputGroup mb={2} data-sentry-element="InputGroup" data-sentry-component="GradesFilter" data-sentry-source-file="GradesFilter.tsx">
      <Stack direction="row" width="100%" gap={0} data-sentry-element="Stack" data-sentry-source-file="GradesFilter.tsx">
        {options.map(option => <Checkbox key={"grade-" + option.value} variant="filterStack" value={option.value} onChange={e => onChange(option.value, e.target.checked)} isChecked={editedFilters[FiltersType.GRADES]?.includes(option.value) ?? false}>
            {option.label}
          </Checkbox>)}
      </Stack>
    </InputGroup>;
};
export default GradesFilter;