import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Heading, Text, useBoolean } from "@chakra-ui/react";
import { ReactNode } from "react";
import { HelperModalType } from "./FilterItem";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { BadgeModalButton } from "@/ui/badges/BadgeModal";
import HelperModal from "@/ui/helperModals/HelperModal";
interface Props {
  children: ReactNode;
  name: string;
  helperModal?: HelperModalType;
}
const FilterAccordionItem = ({
  children,
  name,
  helperModal
}: Props) => {
  const [open, {
    toggle,
    off
  }] = useBoolean();
  return <AccordionItem borderWidth="0" boxShadow="0px -1px 4px rgba(52, 67, 114, 0.08), 0px 2px 8px rgba(52, 67, 114, 0.12)" px={2} py={1} borderRadius="8px" mb={3} data-sentry-element="AccordionItem" data-sentry-component="FilterAccordionItem" data-sentry-source-file="FilterAccordionItem.tsx">
      {({
      isExpanded
    }) => <>
          <AccordionButton justifyContent="space-between" className={isExpanded ? "filter-accordion-expanded" : ""} px={0} _hover={{
        background: "inherit"
      }}>
            <Flex>
              <Heading color="snow.blue-medium" flex="1" variant="h3" textAlign="left">
                {name}
              </Heading>
              {helperModal && <>
                  <BadgeModalButton onClick={toggle}>
                    <SnowdayIcons name="QuestionMark" color="snow.blue" ml={2} />
                  </BadgeModalButton>
                  <HelperModal open={open} onClose={off} title={helperModal.title}>
                    <Text>{helperModal.text}</Text>
                  </HelperModal>
                </>}
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={1} py={3}>
            {children}
          </AccordionPanel>
        </>}
    </AccordionItem>;
};
export default FilterAccordionItem;