import { Checkbox, Flex, InputGroup, Stack, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { eligibilityOptions, noRestrictionOption } from "../../options";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { Restriction } from "@/generated/graphql";
interface CheckboxProps<T> {
  label: string;
  value: T;
  onChange: (value: T, checked: boolean) => void;
  isChecked: boolean;
}
function CheckboxFilter<T extends boolean | Restriction>({
  label,
  value,
  onChange,
  isChecked
}: CheckboxProps<T>) {
  return <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-component="CheckboxFilter" data-sentry-source-file="EligibilityFilter.tsx">
      <Text flexGrow={1} data-sentry-element="Text" data-sentry-source-file="EligibilityFilter.tsx">{label}</Text>
      <Checkbox name={value.toString()} iconSize="24px" variant="snowday" key={"checkbox-" + value} value={value.toString()} isChecked={isChecked} onChange={e => onChange(value, e.target.checked)} data-sentry-element="Checkbox" data-sentry-source-file="EligibilityFilter.tsx" />
    </Flex>;
}
const NoRestrictionFilter = () => {
  const {
    editedFilters,
    onToggle
  } = useFilter();
  const onChange = () => {
    onToggle(FiltersType.NO_RESTRICTIONS, true);
  };
  const isChecked = useMemo(() => {
    return Array.isArray(editedFilters[FiltersType.NO_RESTRICTIONS]) && editedFilters[FiltersType.NO_RESTRICTIONS].includes(true);
  }, [editedFilters[FiltersType.NO_RESTRICTIONS]]);
  return <CheckboxFilter<boolean> label={noRestrictionOption.label} value={noRestrictionOption.value} onChange={onChange} isChecked={isChecked} data-sentry-element="CheckboxFilter" data-sentry-component="NoRestrictionFilter" data-sentry-source-file="EligibilityFilter.tsx" />;
};
const EligibilityFilter = () => {
  const {
    editedFilters,
    onAdd,
    onRemove
  } = useFilter();
  const onChange = (value: Restriction, checked: boolean) => {
    if (checked) {
      onAdd(FiltersType.ELIGIBILITY, value);
    } else {
      onRemove(FiltersType.ELIGIBILITY, value);
    }
  };
  return <InputGroup mb={2} data-sentry-element="InputGroup" data-sentry-component="EligibilityFilter" data-sentry-source-file="EligibilityFilter.tsx">
      <Stack direction="column" width="100%" data-sentry-element="Stack" data-sentry-source-file="EligibilityFilter.tsx">
        <NoRestrictionFilter data-sentry-element="NoRestrictionFilter" data-sentry-source-file="EligibilityFilter.tsx" />
        {eligibilityOptions.map(option => <CheckboxFilter<Restriction> key={option.value} label={option.label} value={option.value} onChange={onChange} isChecked={editedFilters[FiltersType.ELIGIBILITY]?.includes(option.value) ?? false} />)}
      </Stack>
    </InputGroup>;
};
export default EligibilityFilter;