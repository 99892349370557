import { Box, Flex, Input, InputGroup, InputLeftElement, Tag, Text } from "@chakra-ui/react";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { Interest } from "@/generated/graphql";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { useInterestsAutoCompleteSearch } from "@/search/hooks/useInterestsAutoCompleteSearch";
import { makeBold } from "@/utils/index";
const InterestsFilter = () => {
  const {
    editedFilters,
    onAdd,
    onRemove
  } = useFilter();
  const [query, setQuery] = useState<string>();
  const {
    interests
  } = useInterestsAutoCompleteSearch(query);
  const [querying, setQuerying] = useState<boolean>(false);
  const handleAdd = (interest: Interest) => {
    onAdd(FiltersType.INTERESTS, interest);
    setQuery(undefined);
  };
  return <>
      <Box position="relative" data-sentry-element="Box" data-sentry-source-file="InterestsFilter.tsx">
        <InputGroup bg="white" position="relative" mb={2} zIndex={8} onKeyDown={() => !querying && setQuerying(true)} onBlur={() => querying && setQuerying(false)} onClick={() => setQuerying(!querying)} data-sentry-element="InputGroup" data-sentry-source-file="InterestsFilter.tsx">
          <InputLeftElement children={<SnowdayIcons name="Search" boxSize={6} />} data-sentry-element="InputLeftElement" data-sentry-source-file="InterestsFilter.tsx" />
          <Input errorBorderColor="snow.errors" value={query} onChange={e => setQuery(e.target.value)} placeholder="Add more interests to filter on..." data-sentry-element="Input" data-sentry-source-file="InterestsFilter.tsx" />
          <Flex display={querying && query !== "" ? "flex" : "none"} position="absolute" width="100%" bg="white" maxH="240px" overflowY="scroll" top={9} borderBottomRadius={8} boxShadow="0px 1px 2px rgba(52, 67, 114, 0.08), 0px 2px 4px rgba(52, 67, 114, 0.2)" flexDirection="column" zIndex={0} data-sentry-element="Flex" data-sentry-source-file="InterestsFilter.tsx">
            {interests.map(interest => <Flex key={interest.identifier} py={3} pl={10} cursor="pointer" onMouseDown={e => e.preventDefault()} _hover={{
            textDecoration: "underline"
          }} onClick={() => handleAdd(interest)}>
                <Text>
                  {HTMLReactParser(query && interest.name ? makeBold(query, interest.name) : "")}
                </Text>
              </Flex>)}
          </Flex>
        </InputGroup>
        {editedFilters[FiltersType.INTERESTS] && Array.isArray(editedFilters[FiltersType.INTERESTS]) && <Flex gap={2}>
              {editedFilters[FiltersType.INTERESTS].map(interest => <Tag key={"interest-" + interest.identifier} rounded="full" px={{
          base: 3,
          md: 4
        }} py={1} color="snow.blue-dark">
                  {interest.name}
                  <SnowdayIcons name="CloseCircle" ml={2} cursor="pointer" color="snow.blue-dark" onClick={() => onRemove(FiltersType.INTERESTS, interest)} />
                </Tag>)}
            </Flex>}
      </Box>
    </>;
};
export default InterestsFilter;