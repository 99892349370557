import { Accordion, Box } from "@chakra-ui/react";
import FilterAccordionItem from "./FilterAccordionItem";
import FilterItem from "./FilterItem";
import { featureFlags } from "../../featureFlags/utils";
import { FilterCategoriesType, FiltersType } from "../types";
interface FilterItem {
  type: FiltersType;
  stack?: boolean;
}
interface Filter {
  name: string;
  type: FilterCategoriesType;
  items: FilterItem[];
}
const filterOptions: Filter[] = [{
  name: "Quick filters",
  type: FilterCategoriesType.QUICK_FILTERS,
  items: [{
    type: FiltersType.TYPE
  }, {
    type: FiltersType.LOCATION
  }, {
    type: FiltersType.GRADES
  }, {
    type: FiltersType.INTERESTS
  }, {
    type: FiltersType.FINANCIAL_ACCESSIBILITY
  }, {
    type: FiltersType.SELECTIVE,
    stack: false
  }]
}, {
  name: "Advanced Criteria",
  type: FilterCategoriesType.ADVANCED_CRITERIA,
  items: [{
    type: FiltersType.COLLEGE_CREDIT,
    stack: false
  }, {
    type: FiltersType.ONE_ON_ONE,
    stack: false
  }, {
    type: FiltersType.AVAILABILITY
  }, {
    type: FiltersType.DEADLINE
  }]
}, {
  name: "Eligibility Restrictions",
  type: FilterCategoriesType.ELIGIBILITY_RESTRICTIONS,
  items: [{
    type: FiltersType.ELIGIBILITY
  }]
}];
export const Filters = () => {
  return <>
      <Box flexGrow={1} py={5} px={2} overflow="auto" data-sentry-element="Box" data-sentry-source-file="Filters.tsx">
        <Accordion allowMultiple={true} defaultIndex={[0, 1, 2]} data-sentry-element="Accordion" data-sentry-source-file="Filters.tsx">
          {filterOptions.filter(({
          type
        }) => featureFlags[type]).map(filter => <FilterAccordionItem key={"filter-category-" + filter.name} name={filter.name}>
                {filter.items.filter(({
            type
          }) => featureFlags[type]).map((item: FilterItem) => <FilterItem key={"filter-item-" + item.type} type={item.type} stack={item.stack} />)}
              </FilterAccordionItem>)}
        </Accordion>
      </Box>
    </>;
};