import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { usePagination } from "../providers/PaginationProvider";
interface PaginationComponentProps {
  totalResults: number;
  onChange?: (page: number) => void;
}
const PaginationComponent = ({
  totalResults,
  onChange
}: PaginationComponentProps) => {
  const {
    pageNumber,
    pageSize,
    onPageNumberChange
  } = usePagination();
  const totalPages = Math.ceil(totalResults / pageSize);
  const pageNumbers = Array.from({
    length: totalPages
  }, (_, i) => i + 1);
  const handleChange = (newPageNumber: number) => {
    onPageNumberChange(newPageNumber);
    onChange?.(newPageNumber);
  };
  return <Box display="flex" justifyContent="center" alignItems="center" mt="4" flexWrap="wrap" data-sentry-element="Box" data-sentry-component="PaginationComponent" data-sentry-source-file="Pagination.tsx">
      {pageNumbers.map((number, i) => {
      const isActive = pageNumber === i;
      return <Button mx="1" key={number} onClick={() => handleChange(number - 1)} variant={isActive ? "solid" : "ghost"} colorScheme={isActive ? "blue" : "gray"}>
            {number}
          </Button>;
    })}
    </Box>;
};
export default PaginationComponent;