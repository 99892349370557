import { Flex } from "@chakra-ui/react";
import FilterLabel from "./FilterLabel";
import AvailabilityFilter from "./types/AvailabilityFilter";
import CollegeCreditFilter from "./types/CollegeCreditFilter";
import DeadlineFilter from "./types/DeadlineFilter";
import EligibilityFilter from "./types/EligibilityFilter";
import FinancialAccessibilityFilter from "./types/FinancialAccessibilityFilter";
import GradesFilter from "./types/GradesFilter";
import InterestsFilter from "./types/InterestsFilter";
import LocationFilter from "./types/LocationFilter";
import OneOnOneFilter from "./types/OneOnOneFilter";
import SelectiveFilter from "./types/SelectiveFilter";
import TypeFilter from "./types/TypeFilter";
import { FiltersType } from "../types";
import { BadgeModalProps } from "@/ui/badges/BadgeModal";
import { FinancialAccessibilityModal } from "@/ui/badges/FinancialAccessibilityBadge";
export type HelperModalType = {
  text: string;
  title: string;
};
export type FilterTypeItem = {
  component: () => JSX.Element;
  name?: string;
  modalComponent?: (props: BadgeModalProps) => JSX.Element;
  helperModal?: HelperModalType;
};
type FilterType = Record<string, FilterTypeItem>;
export const filterTypes: FilterType = {
  [FiltersType.TYPE]: {
    component: TypeFilter,
    name: "Type"
  },
  [FiltersType.LOCATION]: {
    component: LocationFilter,
    name: "Location"
  },
  [FiltersType.COLLEGE_CREDIT]: {
    component: CollegeCreditFilter,
    name: "College credit"
  },
  [FiltersType.ONE_ON_ONE]: {
    component: OneOnOneFilter,
    name: "1-on-1 programs"
  },
  [FiltersType.SELECTIVE]: {
    component: SelectiveFilter,
    name: "Highly Selective"
  },
  [FiltersType.FINANCIAL_ACCESSIBILITY]: {
    component: FinancialAccessibilityFilter,
    name: "Financial Accessibility",
    modalComponent: FinancialAccessibilityModal
  },
  [FiltersType.INTERESTS]: {
    component: InterestsFilter,
    name: "Interests"
  },
  [FiltersType.GRADES]: {
    component: GradesFilter,
    name: "Current grade",
    helperModal: {
      text: `Selecting a grade level will show only the learning opportunities that are eligible to or highly recommended for students in that grade. Summer programs happen between grades so please select your current grade and not your rising grade. These grades refer to the US grade system and not the UK or other grade systems that differ. Lastly, note that many programs make exceptions for exceptional student situations so keep that in mind when filtering by grade.`,
      title: "Grades"
    }
  },
  [FiltersType.DEADLINE]: {
    component: DeadlineFilter,
    name: "Deadline within"
  },
  [FiltersType.AVAILABILITY]: {
    component: AvailabilityFilter,
    name: "Fits your availability",
    helperModal: {
      text: `Filter by a specific time period to only show the learning
          opportunities that have at least one session that starts and ends
          within that time period. To avoid missing some good opportunities, we
          recommend selecting the start date (and end date) to be a couple days
          before (and a few days after) your available dates as sometimes
          missing the first or last day or two is worth it for the right fit
          program, or sometimes worth slightly modifying your schedule.`,
      title: "Availability"
    }
  },
  [FiltersType.ELIGIBILITY]: {
    component: EligibilityFilter
  }
};
interface FilterItemProps {
  type: keyof typeof filterTypes;
  stack?: boolean;
}
const FilterItem = ({
  type,
  stack = true
}: FilterItemProps) => {
  const {
    component: FilterComponent,
    name,
    modalComponent,
    helperModal
  } = filterTypes[type];
  return <Flex flexDirection={stack ? "column" : "row"} display="flex" alignContent={stack ? "flex-start" : "center"} mb={4} data-sentry-element="Flex" data-sentry-component="FilterItem" data-sentry-source-file="FilterItem.tsx">
      {name && <FilterLabel name={name} modalComponent={modalComponent} helperModal={helperModal} />}
      <FilterComponent data-sentry-element="FilterComponent" data-sentry-source-file="FilterItem.tsx" />
    </Flex>;
};
export default FilterItem;