import { FilterState } from "@/filters/state/reducer"
import { FiltersType } from "@/filters/types"
import {
  LearningOpportunityType,
  FinancialAccessibility,
  Grade,
} from "@/generated/graphql"
import { SortOrdering } from "@/sorting/types"

export const eligibilityMap = {
  FIRST_GEN: "First gen",
  LOW_INCOME: "Low income",
  UNDER_REPRESENTED_MINORITY: "Under-represented minority",
  US_CITIZEN: "US citizen",
  US_RESIDENT: "US resident",
}

export const financialMap: Record<FinancialAccessibility, string> = {
  A_PLUS: "A+",
  A: "A",
  A_MINUS: "A-",
  B_PLUS: "B+",
  B: "B",
  B_MINUS: "B-",
  C_PLUS: "C+",
  NONE: "None",
}

export const gradesMap: Record<Grade, number> = {
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
}

export const typeMap: Record<LearningOpportunityType, string> = {
  SUMMER_PROGRAM: "Summer program",
  SCHOOL_YEAR_PROGRAM: "School year program",
  COMPETITION: "Competition",
}

// Converts an array of filter values (e.g. ["SUMMER_PROGRAM", "SCHOOL_YEAR_PROGRAM"])
// and turns them into something friendly for viewing in Mixpanel
export function mapFilterValuesToReadableStrings(
  filterValues: string[],
  humanFriendlyStringMap: Record<string, string | number>,
): (string | number)[] {
  return filterValues.map((value: string) => {
    return humanFriendlyStringMap[value] || ""
  })
}

export function processOrderingsForAnalytics(
  orderings: SortOrdering[],
): object[] {
  return orderings.map((ordering) => {
    return { Field: ordering.field, Order: ordering.order }
  })
}

type FilterValuesForAnalytics =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | (string | number)[]

export function convertFiltersForAnalytics(
  filters: Partial<FilterState>,
): Record<string, FilterValuesForAnalytics> {
  const eligibility = mapFilterValuesToReadableStrings(
    Array.isArray(filters[FiltersType.ELIGIBILITY])
      ? (filters[FiltersType.ELIGIBILITY] as string[])
      : filters[FiltersType.ELIGIBILITY]
        ? [filters[FiltersType.ELIGIBILITY] as string]
        : [],
    eligibilityMap,
  )

  const financial = mapFilterValuesToReadableStrings(
    Array.isArray(filters[FiltersType.FINANCIAL_ACCESSIBILITY])
      ? (filters[FiltersType.FINANCIAL_ACCESSIBILITY] as string[])
      : filters[FiltersType.FINANCIAL_ACCESSIBILITY]
        ? [filters[FiltersType.FINANCIAL_ACCESSIBILITY] as string]
        : [],
    financialMap,
  )

  const grades = mapFilterValuesToReadableStrings(
    Array.isArray(filters[FiltersType.GRADES])
      ? (filters[FiltersType.GRADES] as string[])
      : filters[FiltersType.GRADES]
        ? [filters[FiltersType.GRADES] as string]
        : [],
    gradesMap,
  )

  const noRestrictions = Array.isArray(filters[FiltersType.NO_RESTRICTIONS])
    ? filters[FiltersType.NO_RESTRICTIONS][0] ?? true
    : filters[FiltersType.NO_RESTRICTIONS] ?? true

  const includeOnline = filters[FiltersType.INCLUDE_ONLINE] ?? true
  const interests = Array.isArray(filters[FiltersType.INTERESTS])
    ? filters[FiltersType.INTERESTS].map(
        (filter: { name: string }) => filter.name,
      )
    : []

  const locationRadius = filters[FiltersType.LOCATION_RADIUS] ?? 0
  const onlineOnly = filters[FiltersType.ONLINE_ONLY] ?? false

  const selective =
    Array.isArray(filters[FiltersType.SELECTIVE]) &&
    filters[FiltersType.SELECTIVE].length > 0 &&
    filters[FiltersType.SELECTIVE][0] === "HIGHLY"
      ? "High"
      : "None"

  const filterType = mapFilterValuesToReadableStrings(
    Array.isArray(filters[FiltersType.TYPE])
      ? (filters[FiltersType.TYPE] as string[])
      : filters[FiltersType.TYPE]
        ? [filters[FiltersType.TYPE] as string]
        : [],
    typeMap,
  )

  const collegeCredit = Array.isArray(filters[FiltersType.COLLEGE_CREDIT])
    ? filters[FiltersType.COLLEGE_CREDIT][0] ?? false
    : filters[FiltersType.COLLEGE_CREDIT] ?? false

  return {
    Eligibility: eligibility,
    "Financial accessibility": financial,
    Grades: grades,
    "No restrictions": noRestrictions,
    "Include online": includeOnline,
    Interests: interests,
    "Location radius": locationRadius,
    "Online only": onlineOnly,
    Selective: selective,
    Type: filterType,
    "College credit": collegeCredit,
  }
}
