import { Box, Container, Flex, Heading, Link as ChakraLink, ListItem, Text, UnorderedList, VStack, Spacer, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import LOResultCardSkeleton from "./result/LOResultCardSkeleton";
import { useSearch } from "../providers/SearchProvider";
import { countLabel, insertAskSnowdayBlocks } from "../utils/domain";
import { useAnalytics } from "@/features/analytics/components/AnalyticsProvider";
import { convertFiltersForAnalytics, processOrderingsForAnalytics } from "@/features/analytics/util/util";
import FilterButton from "@/filters/components/FilterButton";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { useLearningOpportunities } from "@/learningOpportunities/hooks";
import { SaveToListsModal } from "@/lists/components/SaveToListsModal";
import PaginationComponent from "@/pagination/components/Pagination";
import { usePagination } from "@/pagination/providers/PaginationProvider";
import SortingModal from "@/sorting/components/SortingModal";
import { useSortOrderings } from "@/sorting/providers/SortOrderingsProvider";
import { AskSnowdayBlock } from "@/ui/askSnowday/AskSnowdayBlock";
import { useIsExtraLargeScreen } from "@/ui/hooks/useIsExtraLargeScreen";
import { useUpdateQueryString } from "@/utils/hooks/useUpdateQueryString";
const FOCUSED_LO_IDENTIFER_KEY = "focused-lo-identifier";
export const SearchResults = () => {
  const {
    query,
    resetQuery
  } = useSearch();
  const {
    appliedFilters: filters,
    onClear
  } = useFilter();
  const {
    orderings
  } = useSortOrderings();
  const {
    pageNumber,
    pageSize
  } = usePagination();
  const router = useRouter();
  const updateQueryString = useUpdateQueryString();
  const {
    isOpen: isListsModalOpen,
    onOpen: onOpenListsModal,
    onClose: onCloseListsModal
  } = useDisclosure();
  const [learningOpportunityToSave, setLearningOpportunityToSave] = useState<string>();
  const {
    isFetched,
    learningOpportunities = [],
    savedLearningOpportunities = [],
    totalCount,
    refetchSavedLearningOpportunities
  } = useLearningOpportunities({
    query,
    filters
  }, {
    orderings,
    page: pageNumber,
    pageSize
  });
  const losWithSavedData = useMemo(() => {
    const savedLoIds = new Set(savedLearningOpportunities);
    return learningOpportunities.map(lo => {
      return {
        ...lo,
        saved: savedLoIds.has(lo.identifier)
      };
    });
  }, [learningOpportunities, savedLearningOpportunities]);
  const firstResultNumber = pageNumber * pageSize + 1;
  const lastResultNumber = Math.min(firstResultNumber + pageSize - 1, totalCount);
  const isExtraLargeScreen = useIsExtraLargeScreen();
  const hasScrolled = useRef(false);
  useEffect(() => {
    if (hasScrolled.current) {
      return;
    }
    hasScrolled.current = true;
    if (router.query[FOCUSED_LO_IDENTIFER_KEY]) {
      const element = document.getElementById((router.query[FOCUSED_LO_IDENTIFER_KEY] as string));
      if (!element) {
        return;
      }
      const elementRect = element?.getBoundingClientRect();
      const elementOffsetTop = window.scrollY + elementRect.top;
      const offsetToCenter = (window.innerHeight - elementRect.height) / 2;
      window.scrollTo({
        top: elementOffsetTop - offsetToCenter,
        behavior: "smooth"
      });
    }
  }, [router.query[FOCUSED_LO_IDENTIFER_KEY]]);
  const analytics = useAnalytics();
  const isFirstRender = useRef(true);
  // We want to track if the search query has changed to tell Mixpanel if this is a refined search.
  const lastSearchQuery = useRef("");
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
  const searchState = useMemo(() => {
    return {
      query,
      filters,
      orderings,
      pageNumber,
      totalCount: totalCount
    };
  }, [query, filters, orderings, pageNumber, totalCount]);
  const trackSearch = useMemo(() => {
    return _.debounce((params: Record<string, unknown>) => {
      analytics.track("Searched LOs", params);
    }, 1500);
  }, [analytics.track]);
  useEffect(() => {
    if (!isFetched) {
      return;
    }

    // We consider a refined search to be a search where a user changes a filter or ordering,
    // but doesn't change the query.
    const refinedSearch = lastSearchQuery.current == searchState.query;
    lastSearchQuery.current = (searchState.query as string);
    const params = {
      Query: searchState.query,
      Page: searchState.pageNumber,
      Count: totalCount,
      ...convertFiltersForAnalytics(searchState.filters),
      Ordering: processOrderingsForAnalytics(searchState.orderings),
      Refined: refinedSearch
    };
    trackSearch(params);
  }, [searchState, isFetched]);
  const handleBrowseAll = () => {
    resetQuery();
    onClear();
  };
  const handleLoSaveButtonClick = (loIdentifier: string) => {
    setLearningOpportunityToSave(loIdentifier);
    const queryString = updateQueryString({
      [FOCUSED_LO_IDENTIFER_KEY]: loIdentifier
    });
    router.replace({
      pathname: router.pathname,
      query: queryString
    }, undefined, {
      scroll: false
    });
    onOpenListsModal();
  };
  const handleSaveToLists = () => {
    return refetchSavedLearningOpportunities();
  };
  const renderEmpty = () => {
    return <>
        <Container data-sentry-element="Container" data-sentry-source-file="SearchResults.tsx">
          <Heading mb={5} data-sentry-element="Heading" data-sentry-source-file="SearchResults.tsx">Oops, no matches found!</Heading>
          <Text mb={5} data-sentry-element="Text" data-sentry-source-file="SearchResults.tsx">But don't worry, you have options</Text>
          <UnorderedList ml={10} mb={10} data-sentry-element="UnorderedList" data-sentry-source-file="SearchResults.tsx">
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="SearchResults.tsx">
              <Text data-sentry-element="Text" data-sentry-source-file="SearchResults.tsx">Try again with different keywords.</Text>
            </ListItem>
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="SearchResults.tsx">
              <Text data-sentry-element="Text" data-sentry-source-file="SearchResults.tsx">Use fewer words for a broader search.</Text>
            </ListItem>
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="SearchResults.tsx">
              <Text data-sentry-element="Text" data-sentry-source-file="SearchResults.tsx">
                <ChakraLink textDecoration="underline" onClick={handleBrowseAll} data-sentry-element="ChakraLink" data-sentry-source-file="SearchResults.tsx">
                  Browse all
                </ChakraLink>{" "}
                and then filter.
              </Text>
            </ListItem>
          </UnorderedList>
          <Text mb={5} data-sentry-element="Text" data-sentry-source-file="SearchResults.tsx">Still can't find what you're looking for?</Text>
        </Container>
        <Container px={{
        base: 0,
        lg: 4
      }} data-sentry-element="Container" data-sentry-source-file="SearchResults.tsx">
          <AskSnowdayBlock data-sentry-element="AskSnowdayBlock" data-sentry-source-file="SearchResults.tsx" />
        </Container>
      </>;
  };
  const elements = insertAskSnowdayBlocks(losWithSavedData, handleLoSaveButtonClick);
  const renderContent = () => {
    if (isFetched && learningOpportunities.length === 0) {
      return renderEmpty();
    }
    return <>
        {!isFetched && <LOResultCardSkeleton />}
        {elements}
        <PaginationComponent totalResults={totalCount} data-sentry-element="PaginationComponent" data-sentry-source-file="SearchResults.tsx" />
      </>;
  };
  return <>
      <VStack width="100%" data-sentry-element="VStack" data-sentry-source-file="SearchResults.tsx">
        <Flex width="100%" direction="row" alignItems="center" gap="2" mb={3} justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="SearchResults.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="SearchResults.tsx">
            <Text fontStyle="italic" fontSize={14} data-sentry-element="Text" data-sentry-source-file="SearchResults.tsx">
              {isFetched && totalCount > 0 ? `${firstResultNumber}-${lastResultNumber} of ${countLabel(totalCount)} results` : null}
            </Text>
          </Box>
          <Spacer data-sentry-element="Spacer" data-sentry-source-file="SearchResults.tsx" />
          {!isExtraLargeScreen ? <FilterButton /> : null}
          <SortingModal data-sentry-element="SortingModal" data-sentry-source-file="SearchResults.tsx" />
        </Flex>
        <VStack gap={{
        base: 1,
        md: 5
      }} paddingBottom={3} width="100%" data-sentry-element="VStack" data-sentry-source-file="SearchResults.tsx">
          {renderContent()}
        </VStack>
      </VStack>
      <SaveToListsModal learningOpportunityIdentifier={learningOpportunityToSave} isOpen={isListsModalOpen} onClose={onCloseListsModal} onSave={handleSaveToLists} data-sentry-element="SaveToListsModal" data-sentry-source-file="SearchResults.tsx" />
    </>;
};