import { Flex, Text, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { TopSearchDataType } from "@/search/types";
import { stringifySortOrderings } from "@/sorting/utils/stringifySortOrderings";
import SnowdayIcons from "@/ui/icons/SnowdayIcons";
export const TopSearchItem = ({
  topSearch
}: {
  topSearch: TopSearchDataType;
}) => {
  const router = useRouter();
  const {
    icon,
    name,
    filter,
    sort
  } = topSearch;
  const handleClick = useCallback(() => {
    const params = [];
    if (filter) {
      params.push(["filter", JSON.stringify(filter)]);
    }
    if (sort) {
      params.push(["sort", stringifySortOrderings(sort)]);
    }
    if (params.length > 0) {
      router.push(`/search/results?${new URLSearchParams(params)}`);
    } else {
      router.push(`/search/results`);
    }
  }, [filter, sort]);
  return <Flex height="100%" justifyContent="center" py={{
    base: "9px",
    md: "10px"
  }} onClick={handleClick} data-sentry-element="Flex" data-sentry-component="TopSearchItem" data-sentry-source-file="TopSearchItem.tsx">
      <VStack justifyContent="center" data-sentry-element="VStack" data-sentry-source-file="TopSearchItem.tsx">
        <SnowdayIcons boxSize={{
        base: "32px",
        md: "44px"
      }} name={icon} data-sentry-element="SnowdayIcons" data-sentry-source-file="TopSearchItem.tsx" />
        <Text align="center" fontSize={{
        base: 14,
        md: 16
      }} color="snow.blue-dark" fontWeight={700} data-sentry-element="Text" data-sentry-source-file="TopSearchItem.tsx">
          {name}
        </Text>
      </VStack>
    </Flex>;
};