import { useRouter } from "next/router"

import {
  useGetLearningOpportunityQuery,
  useGetSavedLearningOpportunitiesQuery,
} from "@/generated/graphql"
import graphqlRequestClient from "@/graphql/client"
import { parseIdentifier } from "@/utils/string"

export const useLearningOpportunity = () => {
  const router = useRouter()

  const identifier = router?.query?.identifier || ""

  const { data } = useGetLearningOpportunityQuery(
    graphqlRequestClient,
    {
      identifier: parseIdentifier(identifier),
    },
    {
      enabled: !!identifier,
    },
  )
  const getSavedLearningOpportunitiesResult =
    useGetSavedLearningOpportunitiesQuery(graphqlRequestClient)

  const savedLearningOpportunities =
    getSavedLearningOpportunitiesResult.data?.savedLearningOpportunities ?? []

  const learningOpportunity = data
    ? {
        ...data.learningOpportunity,
        saved: savedLearningOpportunities.includes(
          data.learningOpportunity.identifier,
        ),
      }
    : undefined

  return {
    learningOpportunity,
    refetchSavedLearningOpportunities:
      getSavedLearningOpportunitiesResult.refetch,
  }
}
