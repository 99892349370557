import { FormLabel, Text, useBoolean } from "@chakra-ui/react";
import { HelperModalType } from "./FilterItem";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { BadgeModalButton, BadgeModalProps } from "@/ui/badges/BadgeModal";
import HelperModal from "@/ui/helperModals/HelperModal";
interface FilterLabelProps {
  name: string;
  modalComponent?: (props: BadgeModalProps) => JSX.Element;
  helperModal?: HelperModalType;
}
const FilterLabel = ({
  name,
  modalComponent,
  helperModal
}: FilterLabelProps) => {
  const [open, {
    toggle,
    off
  }] = useBoolean();
  const ModalComponent = modalComponent;
  return <FormLabel display="flex" flexDirection="row" fontWeight={700} mb={1} mr={ModalComponent ? 0 : 2} flexShrink={0} data-sentry-element="FormLabel" data-sentry-component="FilterLabel" data-sentry-source-file="FilterLabel.tsx">
      {name}
      {ModalComponent || helperModal ? <>
          <BadgeModalButton onClick={toggle}>
            <SnowdayIcons name="QuestionMark" color="snow.blue" ml={2} />
          </BadgeModalButton>
          {ModalComponent && <ModalComponent open={open} onClose={off} />}
          {helperModal && <HelperModal open={open} onClose={off} title={helperModal.title}>
              <Text>{helperModal.text}</Text>
            </HelperModal>}
        </> : null}
    </FormLabel>;
};
export default FilterLabel;