import { Checkbox, InputGroup, Stack } from "@chakra-ui/react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
export type DeadlineValueType = "1" | "3" | "6";
const options: {
  label: string;
  value: DeadlineValueType;
}[] = [{
  label: "1 month",
  value: "1"
}, {
  label: "3 months",
  value: "3"
}, {
  label: "6 months",
  value: "6"
}];
const DeadlineFilter = () => {
  const {
    editedFilters,
    onUpdate
  } = useFilter();
  const onChange = (value: DeadlineValueType, checked: boolean) => {
    if (checked) {
      onUpdate(FiltersType.DEADLINE, value);
    }
    if (!checked && editedFilters[FiltersType.DEADLINE] === value) {
      onUpdate(FiltersType.DEADLINE, undefined);
    }
  };
  return <InputGroup mb={2} w="100%" data-sentry-element="InputGroup" data-sentry-component="DeadlineFilter" data-sentry-source-file="DeadlineFilter.tsx">
      <Stack direction="row" width="100%" gap={0} data-sentry-element="Stack" data-sentry-source-file="DeadlineFilter.tsx">
        {options.map(option => <Checkbox key={`deadline-${option.value}`} variant="filterStack" value={option.value} onChange={e => onChange(option.value, e.target.checked)} isChecked={editedFilters[FiltersType.DEADLINE]?.includes(option.value) ?? false}>
            {option.label}
          </Checkbox>)}
      </Stack>
    </InputGroup>;
};
export default DeadlineFilter;