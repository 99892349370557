import { Button, HStack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Filters } from "./Filters";
import { useFilter } from "../providers/FiltersProvider";
export const EmbeddedFilters = () => {
  const {
    count,
    editedFilters,
    onClear,
    onApply
  } = useFilter();
  useEffect(() => {
    onApply();
  }, [editedFilters]);
  const handleClear = () => {
    onClear();
  };
  const renderCount = () => {
    if (count === 0) {
      return null;
    }
    return `(${count})`;
  };
  return <>
      <HStack justifyContent="space-between" paddingX={2} mt={1} data-sentry-element="HStack" data-sentry-source-file="EmbeddedFilters.tsx">
        <Text fontWeight="bold" data-sentry-element="Text" data-sentry-source-file="EmbeddedFilters.tsx">Filters {renderCount()}</Text>
        <Button variant="link" color="snow.blue-light-medium" onClick={handleClear} data-sentry-element="Button" data-sentry-source-file="EmbeddedFilters.tsx">
          Reset Filters
        </Button>
      </HStack>
      <Filters data-sentry-element="Filters" data-sentry-source-file="EmbeddedFilters.tsx" />
    </>;
};