import { Checkbox, InputGroup } from "@chakra-ui/react";
import { ChangeEvent, useMemo } from "react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { useInterestsAutoCompleteSearch } from "@/search/hooks/useInterestsAutoCompleteSearch";
const OneOnOneFilter = () => {
  const {
    editedFilters,
    onToggle
  } = useFilter();
  const {
    interests
  } = useInterestsAutoCompleteSearch("1-on-1");
  const oneOnOneInterest = useMemo(() => {
    return interests.find(interest => interest.name === "1-on-1");
  }, [interests]);
  const onChange = (_e: ChangeEvent<HTMLInputElement>) => {
    onToggle(FiltersType.INTERESTS, oneOnOneInterest);
  };
  const isChecked = useMemo(() => {
    return editedFilters[FiltersType.INTERESTS] && Array.isArray(editedFilters[FiltersType.INTERESTS]) && editedFilters[FiltersType.INTERESTS].find(interest => interest.name === "1-on-1") !== undefined;
  }, [editedFilters[FiltersType.INTERESTS]]);
  return <InputGroup mb={2} data-sentry-element="InputGroup" data-sentry-component="OneOnOneFilter" data-sentry-source-file="OneOnOneFilter.tsx">
      <Checkbox iconSize="24px" variant="snowday" isChecked={isChecked} onChange={onChange} data-sentry-element="Checkbox" data-sentry-source-file="OneOnOneFilter.tsx" />
    </InputGroup>;
};
export default OneOnOneFilter;