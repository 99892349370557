import { Box, BoxProps, Card, Flex, Grid, GridItem, HStack, Spinner, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ArrowSlider } from "./ArrowSlider";
import { useDevice } from "../hooks";
interface SectionedGridProps<T> {
  /**
   * The data to display in the grid.
   *
   * Each item will be given one element in this array to render.
   */
  data: T[];
  /**
   * The maximum number of items to display in each section (assuming a full section).
   */
  maxSectionSize: number;
  /**
   * The width of each item in the grid in pixels.
   */
  itemWidth: number;
  /**
   * Whether or not the grid is in a loading state.
   */
  loading?: boolean;
  /**
   * The maximum width of the grid.
   * @default BoxProps["maxWidth"] { md: "100%", lg: "720px" }
   */
  maxWidth?: BoxProps["maxWidth"];
  /**
   * If `true`, no space will be left for the navigation arrows when they are hidden.
   * If `false`, a space will be left as a placeholder.
   * @default false
   */
  disableArrowPlaceholders?: boolean;
  /**
   * A function that renders an item in the grid.
   *
   * Each item will be wrapped in a {@link GridItem} displayed as a {@link Card}.
   */
  renderItem: (item: T, index: number) => React.ReactNode;
  /**
   * Properties to pass to the top-level container of this component.
   */
  containerProps?: BoxProps;
}

/**
 * Modeling for a single section of the grid.
 *
 * This is necessary because display order of the items in the grid is not necessarily the same as
 * the order of the items in the passed data array. For example, the first visible section contains
 * two rows, and should display the first `maxSectionSize` items in the data array. If we let the
 * grid lay itself out with no additional structuring, the first row of the grid (including items
 * off screen) would display the first `maxSectionSize` items in the data array.
 */
interface Section<T> {
  top: T[];
  bottom: T[];
}

/**
 * A grid that displays card-based items in sections.
 *
 * Users on desktop can click on arrows to scroll between the sections.
 *
 * Users on mobile can swipe to scroll the full view directly.
 */
export function SectionedGrid<T>(props: SectionedGridProps<T>) {
  const {
    data,
    itemWidth,
    loading,
    renderItem,
    maxSectionSize,
    containerProps
  } = props;
  const disableArrowPlaceholders = props.disableArrowPlaceholders ?? false;
  const maxWidth = props.maxWidth ?? {
    sm: "300px",
    md: "100%",
    lg: "720px"
  };
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const numSections = Math.ceil(data.length / maxSectionSize);
  const numItemsPerSectionRow = Math.ceil(maxSectionSize / 2);
  const numItemsPerGridRow = Math.ceil(data.length / 2);
  const gap = useBreakpointValue({
    base: 8,
    md: 18
  }) ?? 18;
  const sectionWidth = itemWidth * numItemsPerSectionRow + (numItemsPerSectionRow - 1) * gap;
  const gridContainerWidth = numItemsPerGridRow * itemWidth + numItemsPerGridRow * gap;
  const {
    isDesktop
  } = useDevice();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [hideLeftArrow, setHideLeftArrow] = useState(currentSectionIndex === 0);
  const [hideRightArrow, setHideRightArrow] = useState(currentSectionIndex === numSections - 1);
  useEffect(() => {
    setHideLeftArrow(currentSectionIndex === 0);
    setHideRightArrow(currentSectionIndex === numSections - 1);
    scrollContainerRef.current?.scrollTo({
      left: currentSectionIndex * sectionWidth,
      behavior: "smooth"
    });
  }, [currentSectionIndex, numSections]);
  const sections: Section<T>[] = useMemo(() => {
    const result: Section<T>[] = [];
    for (let i = 0; i < numSections; i++) {
      const startIndex = i * maxSectionSize;
      const endIndex = Math.min(startIndex + maxSectionSize, data.length);

      // The last section may not have enough items to completely fill.
      const sectionSize = endIndex - startIndex;
      const endRowIndex = Math.ceil(startIndex + sectionSize / 2);
      result.push({
        top: data.slice(startIndex, endRowIndex),
        bottom: data.slice(endRowIndex, endIndex)
      });
    }
    return result;
  }, [data, maxSectionSize, numSections]);
  if (loading) {
    return <Flex justify="center" py={10}>
        <Spinner data-testid="scrollable-grid-spinner" emptyColor="snow.blue-light" />
      </Flex>;
  }
  const handleScrollLeft = () => {
    const newSectionIndex = currentSectionIndex - 1;
    setCurrentSectionIndex(newSectionIndex);
  };
  const handleScrollRight = () => {
    const newSectionIndex = currentSectionIndex + 1;
    setCurrentSectionIndex(newSectionIndex);
  };
  const renderLeftArrow = () => {
    if (!isDesktop || disableArrowPlaceholders) {
      return null;
    }
    return <ArrowSlider direction="left" onClick={handleScrollLeft} hide={hideLeftArrow} data-sentry-element="ArrowSlider" data-sentry-component="renderLeftArrow" data-sentry-source-file="SectionedGrid.tsx" />;
  };
  const renderRightArrow = () => {
    if (!isDesktop || disableArrowPlaceholders) {
      return null;
    }
    return <ArrowSlider direction="right" onClick={handleScrollRight} hide={hideRightArrow} data-sentry-element="ArrowSlider" data-sentry-component="renderRightArrow" data-sentry-source-file="SectionedGrid.tsx" />;
  };
  const renderSections = () => {
    return [...sections.map((section, index) => {
      return renderItems(section.top, index);
    }), ...sections.map((section, index) => {
      return renderItems(section.bottom, index);
    })];
  };
  const renderItems = (items: T[], sectionIndex: number) => {
    return items.map((item, index) => {
      const key = sectionIndex * numItemsPerSectionRow + index;
      return <GridItem key={key} as={Card} cursor="pointer" height="100%" boxShadow="0px 1px 2px rgba(52, 67, 114, 0.08), 0px 2px 4px rgba(52, 67, 114, 0.2)" width={itemWidth}>
          {renderItem(item, index)}
        </GridItem>;
    });
  };
  return <HStack justifyContent="center" {...containerProps} data-sentry-element="HStack" data-sentry-component="SectionedGrid" data-sentry-source-file="SectionedGrid.tsx">
      {renderLeftArrow()}
      <Box px={{
      base: 0,
      md: 20,
      lg: 0
    }} maxWidth={maxWidth} className="no-scrollbar" overflowX={{
      base: "scroll",
      lg: "hidden"
    }} ref={scrollContainerRef} data-sentry-element="Box" data-sentry-source-file="SectionedGrid.tsx">
        <HStack m={1} width={gridContainerWidth} data-sentry-element="HStack" data-sentry-source-file="SectionedGrid.tsx">
          <Grid display="grid" alignItems="stretch" gridTemplateColumns={`repeat(${numItemsPerGridRow}, 1fr)`} gridAutoRows="1fr" gap={`${gap}px`} data-sentry-element="Grid" data-sentry-source-file="SectionedGrid.tsx">
            {renderSections()}
          </Grid>
        </HStack>
      </Box>
      {renderRightArrow()}
    </HStack>;
}