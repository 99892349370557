type GPS = {
  latitude: number
  longitude: number
}

export default function useGPS() {
  const requestGPS = async (): Promise<GPS> => {
    if (!navigator.geolocation) {
      return Promise.reject("No geolocation available")
    }
    try {
      const { coords } = await getCurrentPosition()
      return Promise.resolve({
        latitude: coords.latitude,
        longitude: coords.longitude,
      })
    } catch {
      throw "No currentPosition available"
    }
  }

  const getCurrentPosition = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject),
    )
  }

  return {
    requestGPS,
  }
}
