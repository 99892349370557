import { Box, Container, useBreakpointValue } from "@chakra-ui/react";
import { TopSearchItem } from "./TopSearchItem";
import { useTopSearchData } from "@/search/hooks/useTopSearchData";
import { TopSearchDataType } from "@/search/types";
import ComponentHeading from "@/ui/common/ComponentHeading";
import SnowdayIcons from "@/ui/icons/SnowdayIcons";
import { SectionedGrid } from "@/ui/sectionedGrid/SectionedGrid";
export const TopSearches = () => {
  const topSearches = useTopSearchData();
  const itemWidth = useBreakpointValue({
    base: 100,
    md: 120
  }) ?? 120;
  const renderTopSearch = (topSearch: TopSearchDataType) => {
    return <TopSearchItem topSearch={topSearch} data-sentry-element="TopSearchItem" data-sentry-component="renderTopSearch" data-sentry-source-file="TopSearches.tsx" />;
  };
  return <Box as="section" mt={{
    base: 4,
    lg: 8
  }} mb={{
    base: 10
  }} pl={{
    base: 3,
    md: 0
  }} justifyContent="center" data-testid="top-searches" data-sentry-element="Box" data-sentry-component="TopSearches" data-sentry-source-file="TopSearches.tsx">
      <Container px={{
      base: 0,
      md: 20,
      lg: 0
    }} maxW={{
      md: "100%",
      lg: "720px"
    }} data-sentry-element="Container" data-sentry-source-file="TopSearches.tsx">
        <ComponentHeading icon={<SnowdayIcons name="Snowflake" color="snow.blue" />} title="Top Searches" color="snow.blue-medium" data-sentry-element="ComponentHeading" data-sentry-source-file="TopSearches.tsx" />
      </Container>
      <SectionedGrid data={topSearches} maxSectionSize={10} itemWidth={itemWidth} renderItem={renderTopSearch} data-sentry-element="SectionedGrid" data-sentry-source-file="TopSearches.tsx" />
    </Box>;
};