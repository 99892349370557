import usePlacesAutocomplete from "use-places-autocomplete"

export const useGoogleMaps = () => {
  const {
    init,
    ready,
    value: query,
    suggestions: { data: results },
    setValue: setQuery,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "placesAPICallback",
    debounce: 300,
    requestOptions: {
      types: ["postal_code", "locality"],
    },
    cacheKey: "3",
  })

  return {
    init,
    query,
    ready,
    setQuery,
    clearSuggestions,
    results,
  }
}
