import { Box, Button, ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Heading, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Radio, RadioGroup, Stack, Text, useDisclosure } from "@chakra-ui/react";
import AlarmIcon from "../../ui/icons/Alarm";
import MedalIcon from "../../ui/icons/Medal";
import MoneybagIcons from "../../ui/icons/Moneybag";
import SparkleIcon from "../../ui/icons/Sparkle";
import { INITIAL_SORT_ORDERINGS, useSortOrderings } from "../providers/SortOrderingsProvider";
import { SortField, SortOrder, SortOrdering } from "../types";
import SortIcon from "@/icons/Sort";
import { SnowdayOverlay } from "@/ui/common/SnowdayOverlay";
import { useWindowSize, breakpoints, Size } from "@/ui/hooks";
import GPSPinIcon from "@/ui/icons/GPSPin";
interface SortModalProps {
  onChange?: (newOrderings: SortOrdering[]) => void;
}
export default function SortingModal(props: SortModalProps) {
  const {
    orderings,
    onChange
  } = useSortOrderings();
  const size: Size = useWindowSize();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const value = orderings.length === 1 ? orderings[0].field : "relevancy";
  const handleChange = (newOrderings: SortOrdering[]) => {
    props.onChange?.(newOrderings);
    onChange(newOrderings);
  };
  const renderCurrentIcon = () => {
    switch (value) {
      case "selectivity":
        return <MedalIcon width="24px" height="24px" />;
      case "relevancy":
        return <SparkleIcon width="24px" height="24px" />;
      case "deadline":
        return <AlarmIcon width="24px" height="24px" />;
      case "financialAccessibility":
        return <MoneybagIcons height="24px" width="24px" />;
      case "distance":
        return <GPSPinIcon height="24px" width="24px" />;
    }
    return null;
  };
  if (size.width && size.width < breakpoints.md) {
    return <ButtonGroup>
        <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>Sort results by</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody>
              <SortingRadioGroup onChange={handleChange} onClose={onClose} value={orderings} />
            </DrawerBody>
            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
        <Button variant="secondary" size="sm" aria-label="Sort results" pl={2} pr={3} py={0} onClick={onOpen} isActive={isOpen} color="snow.blue-light-medium" _hover={{
        bg: "snow.blue",
        color: "snow.blue-dark"
      }} _active={{
        bg: "snow.blue",
        color: "snow.blue-dark"
      }}>
          <SortIcon color="inherit" boxSize={6} />
          <Text color="inherit" variant="smallBody">
            Sort:
          </Text>
          <Box ml={1}>{renderCurrentIcon()}</Box>
        </Button>
      </ButtonGroup>;
  }
  return <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end" data-sentry-element="Popover" data-sentry-component="SortingModal" data-sentry-source-file="SortingModal.tsx">
      {isOpen ? <SnowdayOverlay /> : null}
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="SortingModal.tsx">
        <Flex direction="row" alignItems="center" gap={2} data-sentry-element="Flex" data-sentry-source-file="SortingModal.tsx">
          <ButtonGroup data-sentry-element="ButtonGroup" data-sentry-source-file="SortingModal.tsx">
            <Button variant="secondary" size="sm" aria-label="Search" pl={2} pr={3} py={0} onClick={onOpen} isActive={isOpen} color="snow.blue-light-medium" _hover={{
            bg: "snow.blue",
            color: "snow.blue-dark"
          }} _active={{
            bg: "snow.blue",
            color: "snow.blue-dark"
          }} data-sentry-element="Button" data-sentry-source-file="SortingModal.tsx">
              <SortIcon color="inherit" boxSize={6} data-sentry-element="SortIcon" data-sentry-source-file="SortingModal.tsx" />
              <Text color="inherit" variant="smallBody" data-sentry-element="Text" data-sentry-source-file="SortingModal.tsx">
                Sort:
              </Text>
              <Box ml={1} data-sentry-element="Box" data-sentry-source-file="SortingModal.tsx">{renderCurrentIcon()}</Box>
            </Button>
          </ButtonGroup>
        </Flex>
      </PopoverTrigger>
      <PopoverContent data-sentry-element="PopoverContent" data-sentry-source-file="SortingModal.tsx">
        <PopoverCloseButton size={"md"} p={6} data-sentry-element="PopoverCloseButton" data-sentry-source-file="SortingModal.tsx" />
        <PopoverBody px={8} py={10} data-sentry-element="PopoverBody" data-sentry-source-file="SortingModal.tsx">
          <Stack direction="column" gap={2} data-sentry-element="Stack" data-sentry-source-file="SortingModal.tsx">
            <Heading mx={2} variant="h3" data-sentry-element="Heading" data-sentry-source-file="SortingModal.tsx">
              Sort results by
            </Heading>
            <SortingRadioGroup onChange={handleChange} onClose={onClose} value={orderings} variant="snowday" data-sentry-element="SortingRadioGroup" data-sentry-source-file="SortingModal.tsx" />
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>;
}
interface SortingRadioGroupProps {
  onChange: (value: SortOrdering[]) => void;
  onClose: () => void;
  value: SortOrdering[];
  variant?: string;
}
function SortingRadioGroup({
  onChange,
  value,
  onClose
}: SortingRadioGroupProps) {
  const radioGroupValue = value.length === 1 ? value[0].field : "relevancy";
  return <RadioGroup onChange={(field: SortField) => {
    if (field === "relevancy") {
      onChange(INITIAL_SORT_ORDERINGS);
    } else {
      onChange([{
        field,
        order: SortOrder.Asc
      }]);
    }
    onClose();
  }} value={radioGroupValue} data-sentry-element="RadioGroup" data-sentry-component="SortingRadioGroup" data-sentry-source-file="SortingModal.tsx">
      <Stack direction="column" data-sentry-element="Stack" data-sentry-source-file="SortingModal.tsx">
        <Flex flexDirection="row" width="100%" data-sentry-element="Flex" data-sentry-source-file="SortingModal.tsx">
          <Box flexBasis="24px" data-sentry-element="Box" data-sentry-source-file="SortingModal.tsx">
            <SparkleIcon height="24px" width="24px" color="snow.blue-medium" data-sentry-element="SparkleIcon" data-sentry-source-file="SortingModal.tsx" />
          </Box>
          <Radio value="relevancy" flexDirection={"row-reverse"} alignContent={"center"} justifyContent={"space-between"} variant="snowday" flexGrow={1} data-sentry-element="Radio" data-sentry-source-file="SortingModal.tsx">
            Relevancy
          </Radio>
        </Flex>
        <Flex flexDirection="row" width="100%" data-sentry-element="Flex" data-sentry-source-file="SortingModal.tsx">
          <Box flexBasis="24px" data-sentry-element="Box" data-sentry-source-file="SortingModal.tsx">
            <MedalIcon height="24px" width="24px" color="snow.blue-medium" data-sentry-element="MedalIcon" data-sentry-source-file="SortingModal.tsx" />
          </Box>
          <Radio value="selectivity" flexDirection={"row-reverse"} alignContent={"center"} justifyContent={"space-between"} variant="snowday" flexGrow={1} data-sentry-element="Radio" data-sentry-source-file="SortingModal.tsx">
            Highly Selective
          </Radio>
        </Flex>
        <Flex flexDirection="row" width="100%" data-sentry-element="Flex" data-sentry-source-file="SortingModal.tsx">
          <Box flexBasis="24px" data-sentry-element="Box" data-sentry-source-file="SortingModal.tsx">
            <AlarmIcon height="24px" width="24px" color="snow.blue-medium" data-sentry-element="AlarmIcon" data-sentry-source-file="SortingModal.tsx" />
          </Box>
          <Radio value="deadline" flexDirection={"row-reverse"} alignContent={"center"} justifyContent={"space-between"} variant="snowday" flexGrow={1} data-sentry-element="Radio" data-sentry-source-file="SortingModal.tsx">
            Deadline
          </Radio>
        </Flex>
        <Flex flexDirection="row" width="100%" data-sentry-element="Flex" data-sentry-source-file="SortingModal.tsx">
          <Box flexBasis="24px" data-sentry-element="Box" data-sentry-source-file="SortingModal.tsx">
            <MoneybagIcons height="24px" width="24px" color="snow.blue-medium" data-sentry-element="MoneybagIcons" data-sentry-source-file="SortingModal.tsx" />
          </Box>
          <Radio value="financialAccessibility" flexDirection={"row-reverse"} alignContent={"center"} justifyContent={"space-between"} variant="snowday" flexGrow={1} data-sentry-element="Radio" data-sentry-source-file="SortingModal.tsx">
            Financial Accessibility
          </Radio>
        </Flex>
        <Flex flexDirection="row" width="100%" data-sentry-element="Flex" data-sentry-source-file="SortingModal.tsx">
          <Box flexBasis="24px" data-sentry-element="Box" data-sentry-source-file="SortingModal.tsx">
            <GPSPinIcon height="24px" width="24px" color="snow.blue-medium" data-sentry-element="GPSPinIcon" data-sentry-source-file="SortingModal.tsx" />
          </Box>
          <Radio value="distance" flexDirection={"row-reverse"} alignContent={"center"} justifyContent={"space-between"} variant="snowday" flexGrow={1} data-sentry-element="Radio" data-sentry-source-file="SortingModal.tsx">
            Distance
          </Radio>
        </Flex>
      </Stack>
    </RadioGroup>;
}