import { useMemo } from "react"

import { FilterState } from "../../filters/state/reducer"
import { SortOrdering } from "@/features/sorting/types"
import { useGetLearningOpportunitiesQuery } from "@/generated/graphql"
import graphqlRequestClient from "@/graphql/client"
import { mapFilterToAPI } from "@/utils/domain"

interface Arguments {
  query?: string
  filters?: FilterState
}

interface Options {
  orderings?: SortOrdering[]
  page?: number
  pageSize?: number
}

export const useLearningOpportunities = (
  args: Arguments,
  options: Options = {},
) => {
  const { query } = args
  const filters = args.filters ?? {}

  const completeFilters = useMemo(() => {
    return { ...mapFilterToAPI(filters) }
  }, [filters])

  const { page, pageSize } = options
  const orderings = options.orderings ?? []

  const getLearningOpportunitiesResult = useGetLearningOpportunitiesQuery(
    graphqlRequestClient,
    {
      query,
      filter: completeFilters,
      page,
      limit: pageSize,
      orderings,
    },
    {
      enabled: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
    },
  )

  return {
    isFetched: getLearningOpportunitiesResult.isFetched,
    learningOpportunities:
      getLearningOpportunitiesResult.data?.learningOpportunities
        .learningOpportunities ?? [],
    savedLearningOpportunities:
      getLearningOpportunitiesResult.data?.savedLearningOpportunities ?? [],
    totalCount:
      getLearningOpportunitiesResult.data?.learningOpportunities.totalCount ??
      0,
    refetchSavedLearningOpportunities: getLearningOpportunitiesResult.refetch,
  }
}
