import { Box, Checkbox, Divider, Flex, HStack, Input, InputGroup, InputLeftElement, Select, Spinner, Switch, Text, useToast } from "@chakra-ui/react";
import HTMLReactParser from "html-react-parser";
import Script from "next/script";
import { useEffect, useState } from "react";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { radiusValues } from "../../options";
import { GMapsId } from "@/config";
import useGPS from "@/filters/hooks/useGPS";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { useGoogleMaps } from "@/google/maps/hooks";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { makePlacesBold } from "@/utils/string";
const GPSDescription = "Current GPS location";
const LocationFilter = () => {
  const toast = useToast();
  const {
    onUpdate,
    editedFilters
  } = useFilter();
  const {
    requestGPS
  } = useGPS();
  const {
    init: initGoogleMaps,
    ready,
    query,
    setQuery,
    clearSuggestions,
    results
  } = useGoogleMaps();
  const [error, setError] = useState<boolean>(false);
  const [querying, setQuerying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (editedFilters[FiltersType.LOCATION]?.name) {
      setQuery(editedFilters[FiltersType.LOCATION].name, false);
    }
  }, []);
  const handleAdd = (description: string) => {
    setQuery(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({
      address: description
    }).then(results => {
      const {
        lat,
        lng
      } = getLatLng(results[0]);
      onUpdate(FiltersType.LOCATION, {
        latitude: lat,
        longitude: lng,
        name: description
      });
    });
  };
  const handleGps = async () => {
    try {
      setError(false);
      setLoading(() => true);
      setQuery(GPSDescription, false);
      clearSuggestions();
      const {
        latitude,
        longitude
      } = await requestGPS();
      onUpdate(FiltersType.LOCATION, {
        latitude,
        longitude,
        name: GPSDescription
      });
      setLoading(() => false);
    } catch (error) {
      toast({
        title: "Error getting your location.",
        description: "We couldn't get your location. Please make sure you have given permissions and try again.",
        status: "error",
        duration: 4000,
        isClosable: true
      });
      setError(true);
      setQuery("", false);
      clearSuggestions();
      setLoading(() => false);
    }
  };
  const clearGps = () => {
    setError(false);
    setQuery("", false);
    clearSuggestions();
    onUpdate(FiltersType.LOCATION, undefined);
    setQuerying(true);
  };
  const onlineOnly = editedFilters[FiltersType.ONLINE_ONLY];
  return <>
      <Script src={`https://maps.googleapis.com/maps/api/js?key=${GMapsId}&libraries=places&callback=placesAPICallback`} onReady={initGoogleMaps} data-sentry-element="Script" data-sentry-source-file="LocationFilter.tsx" />
      <Box position="relative" zIndex={9} data-sentry-element="Box" data-sentry-source-file="LocationFilter.tsx">
        <InputGroup mb={2} bg="white" position="relative" zIndex={8} onBlur={() => setQuerying(false)} onClick={() => !loading && setQuerying(querying => !querying)} data-sentry-element="InputGroup" data-sentry-source-file="LocationFilter.tsx">
          <InputLeftElement children={loading ? <Spinner boxSize={6} /> : <SnowdayIcons name="Location" color="snow.blue-medium" boxSize={6} />} data-sentry-element="InputLeftElement" data-sentry-source-file="LocationFilter.tsx" />
          <Input isDisabled={loading || onlineOnly || !ready} errorBorderColor="snow.errors" placeholder="Enter a city, state, or zip code..." value={query} isInvalid={error} onChange={e => {
          if (!querying) {
            setQuerying(true);
          }
          if (error) {
            setError(false);
          }
          setQuery(e.target.value);
        }} onKeyDown={e => {
          // Clear out the complete if the user hits backspace
          // and the query is GPSDescription
          if (e.key === "Backspace" && query === GPSDescription) {
            clearGps();
          }
        }} data-sentry-element="Input" data-sentry-source-file="LocationFilter.tsx" />
          <Flex display={querying ? "flex" : "none"} position="absolute" width="100%" bg="white" top={9} borderBottomRadius={8} boxShadow="0px 1px 2px rgba(52, 67, 114, 0.08), 0px 2px 4px rgba(52, 67, 114, 0.2)" flexDirection="column" zIndex={0} data-sentry-element="Flex" data-sentry-source-file="LocationFilter.tsx">
            <Flex pt={2} pb={1} alignItems="center" cursor="pointer" onMouseDown={e => e.preventDefault()} onClick={e => {
            e.preventDefault();
            handleGps();
          }} data-sentry-element="Flex" data-sentry-source-file="LocationFilter.tsx">
              <Flex w={10} h={10} justifyContent="center" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="LocationFilter.tsx">
                <SnowdayIcons name="GpsPin" boxSize={6} data-sentry-element="SnowdayIcons" data-sentry-source-file="LocationFilter.tsx" />
              </Flex>
              <Text decoration={query === GPSDescription ? "underline" : undefined} data-sentry-element="Text" data-sentry-source-file="LocationFilter.tsx">
                Use current location
              </Text>
            </Flex>
            <Box px={3} data-sentry-element="Box" data-sentry-source-file="LocationFilter.tsx">
              <Divider data-sentry-element="Divider" data-sentry-source-file="LocationFilter.tsx" />
            </Box>
            {results.filter(place => {
            const country = place.terms[place.terms.length - 1];
            const isUsa = country.value === "USA" || country.value === "US" || country.value === "United States";
            // Only allow postal code searches within the US
            return !place.types.includes("postal_code") || isUsa;
          }).map(place => <Flex key={place.place_id} py={3} pl={10} cursor="pointer" _hover={{
            textDecoration: "underline"
          }} onMouseDown={e => e.preventDefault()} onClick={e => {
            e.preventDefault();
            handleAdd(place.description);
          }}>
                  <Text>
                    {HTMLReactParser(makePlacesBold(place.matched_substrings, place.description))}
                  </Text>
                </Flex>)}
          </Flex>
        </InputGroup>
      </Box>
      <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="LocationFilter.tsx">
        <Text mr={2} variant="smallBody" data-sentry-element="Text" data-sentry-source-file="LocationFilter.tsx">
          Radius
        </Text>
        <Box w={{
        base: "160px"
      }} data-sentry-element="Box" data-sentry-source-file="LocationFilter.tsx">
          <Select disabled={onlineOnly} value={editedFilters[FiltersType.LOCATION_RADIUS] ?? 25} onChange={e => onUpdate(FiltersType.LOCATION_RADIUS, parseInt(e.target.value))} data-sentry-element="Select" data-sentry-source-file="LocationFilter.tsx">
            <option disabled>Select radius</option>
            {radiusValues.map(value => <option value={value} key={String(value)}>
                {`${value} mi`}
              </option>)}
          </Select>
        </Box>
      </Flex>
      <HStack alignItems="center" justifyContent="space-between" my={2} data-sentry-element="HStack" data-sentry-source-file="LocationFilter.tsx">
        <HStack data-sentry-element="HStack" data-sentry-source-file="LocationFilter.tsx">
          <Text variant="smallBody" mr={1} data-sentry-element="Text" data-sentry-source-file="LocationFilter.tsx">
            Include Online
          </Text>
          <Checkbox iconSize="24px" variant="snowday" isChecked={editedFilters[FiltersType.INCLUDE_ONLINE]} disabled={onlineOnly} onChange={e => {
          onUpdate(FiltersType.INCLUDE_ONLINE, e.target.checked);
        }} data-sentry-element="Checkbox" data-sentry-source-file="LocationFilter.tsx" />
        </HStack>
        <HStack data-sentry-element="HStack" data-sentry-source-file="LocationFilter.tsx">
          <Text variant="smallBody" data-sentry-element="Text" data-sentry-source-file="LocationFilter.tsx">Online Only</Text>
          <Switch isChecked={onlineOnly} onChange={e => {
          if (e.target.checked) {
            onUpdate(FiltersType.LOCATION, undefined);
            setQuery("");
            onUpdate(FiltersType.INCLUDE_ONLINE, true);
          }
          onUpdate(FiltersType.ONLINE_ONLY, e.target.checked);
        }} data-sentry-element="Switch" data-sentry-source-file="LocationFilter.tsx" />
        </HStack>
      </HStack>
    </>;
};
export default LocationFilter;