import { Checkbox, IconProps, InputGroup, Stack } from "@chakra-ui/react";
import { ComponentType } from "react";
import { useFilter } from "@/features/filters/providers/FiltersProvider";
import { FiltersType } from "@/features/filters/types";
import { LearningOpportunityType } from "@/generated/graphql";
import CompetitionIcon from "@/ui/icons/Competition";
import GraduationCapIcon from "@/ui/icons/GraduationCap";
import SunIcon from "@/ui/icons/Sun";
const options: {
  label: string;
  Icon: ComponentType<IconProps>;
  value: LearningOpportunityType;
  size?: number;
  disabled?: boolean;
}[] = [{
  label: "Summer",
  Icon: SunIcon,
  value: LearningOpportunityType.SummerProgram
}, {
  label: "School Year",
  Icon: GraduationCapIcon,
  value: LearningOpportunityType.SchoolYearProgram
}, {
  label: "Competition",
  Icon: CompetitionIcon,
  value: LearningOpportunityType.Competition,
  size: 5
}];
const TypeFilter = () => {
  const {
    editedFilters,
    onToggle
  } = useFilter();
  const onChange = (value: LearningOpportunityType, _checked: boolean) => {
    onToggle(FiltersType.TYPE, value);
  };
  return <InputGroup mb={2} w="100%" data-sentry-element="InputGroup" data-sentry-component="TypeFilter" data-sentry-source-file="TypeFilter.tsx">
      <Stack direction="row" width="100%" gap={0} data-sentry-element="Stack" data-sentry-source-file="TypeFilter.tsx">
        {options.map(option => {
        const isChecked = editedFilters[FiltersType.TYPE]?.includes(option.value) ?? false;
        return <Checkbox key={`type-${option.value}`} variant="filterStack" value={option.value} disabled={option.disabled ?? false} onChange={e => onChange(option.value, e.target.checked)} isChecked={isChecked} textAlign="center">
              <option.Icon boxSize={option.size ?? 6} />
              <br />
              {option.label}
            </Checkbox>;
      })}
      </Stack>
    </InputGroup>;
};
export default TypeFilter;