import { Checkbox, InputGroup } from "@chakra-ui/react";
import { useMemo } from "react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
import { Selective } from "@/generated/graphql";
const SelectiveFilter = () => {
  const {
    editedFilters,
    onToggle
  } = useFilter();
  const onChange = () => {
    onToggle(FiltersType.SELECTIVE, Selective.Highly);
  };
  const isChecked = useMemo(() => {
    return editedFilters[FiltersType.SELECTIVE]?.includes(Selective.Highly);
  }, [editedFilters[FiltersType.SELECTIVE]]);
  return <>
      <InputGroup mb={2} data-sentry-element="InputGroup" data-sentry-source-file="SelectiveFilter.tsx">
        <Checkbox iconSize="24px" variant="snowday" isChecked={isChecked} onChange={onChange} data-sentry-element="Checkbox" data-sentry-source-file="SelectiveFilter.tsx" />
      </InputGroup>
    </>;
};
export default SelectiveFilter;