import { Box, HStack, Input, InputGroup, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Stack, Switch, Text, VStack } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { AvailabilityType } from "@/filters/state/reducer";
import { FiltersType } from "@/filters/types";
import { formatInputDate } from "@/utils/string";
const AvailabilityFilter = () => {
  const [filterByMonths, setFilterByMonths] = useState(false);
  const {
    editedFilters,
    onUpdate
  } = useFilter();
  const [localState, setLocalState] = useState<AvailabilityType | undefined>(editedFilters[FiltersType.AVAILABILITY] ? {
    ...editedFilters[FiltersType.AVAILABILITY]
  } : undefined);
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const monthRangeToSliderRange = (start: Date | string | undefined, end: Date | string | undefined): number[] => {
    const startMonth = start ? new Date(start) : undefined;
    const endMonth = end ? new Date(end) : undefined;
    const currentDate = new Date();
    const startYearOffset = startMonth ? startMonth.getFullYear() - currentDate.getFullYear() : 0;
    const endYearOffset = endMonth ? endMonth.getFullYear() - currentDate.getFullYear() : 0;
    return [startMonth ? startYearOffset * 12 + startMonth.getMonth() - currentDate.getMonth() : 0, endMonth ? endYearOffset * 12 + endMonth.getMonth() - currentDate.getMonth() : 11];
  };
  const monthRange = monthRangeToSliderRange(editedFilters[FiltersType.AVAILABILITY]?.start, editedFilters[FiltersType.AVAILABILITY]?.end);
  const onChange = (type: "start" | "end", value: string) => {
    const newValue = new Date(value);
    const payload = {
      ...localState,
      [type]: newValue
    };
    setLocalState(payload);
    setStartError(payload.start && payload.end ? payload.start > payload.end : false);
    setEndError(payload.start && payload.end ? payload.end < payload.start : false);
    if (!startError && !endError) {
      onUpdate(FiltersType.AVAILABILITY, payload);
    }
  };
  const handleDateMonthToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterByMonths(event.target.checked);
  };
  const handleMonthSliderChange = (value: number[]) => {
    const [monthRangeStart, monthRangeEnd] = sliderRangeToMonthRange(value);
    const payload = {
      ...localState,
      start: monthRangeStart,
      end: monthRangeEnd
    };
    setLocalState(payload);
    onUpdate(FiltersType.AVAILABILITY, payload);
  };
  const renderFilterControl = () => {
    if (filterByMonths) {
      return renderMonthFilterControl();
    } else {
      return renderDatesFilterControl();
    }
  };
  const sliderRangeToMonthRange = (value: number[]): Date[] => {
    const currentDate = new Date();
    const monthRangeStart = new Date(currentDate.getFullYear(), currentDate.getMonth() + value[0], 1);
    const monthAfterEndStart = new Date(currentDate.getFullYear(), currentDate.getMonth() + value[1] + 1);
    const monthRangeEnd = new Date(monthAfterEndStart);
    monthRangeEnd.setDate(monthRangeEnd.getDate() - 1);
    return [monthRangeStart, monthRangeEnd];
  };
  const renderMonthFilterControl = () => {
    const formatOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short"
    };
    const [monthRangeStart, monthRangeEnd] = sliderRangeToMonthRange(monthRange);
    return <>
        <RangeSlider aria-label={["start-month", "end-month"]} min={0} max={11} step={1} defaultValue={[0, 11]} value={monthRange} onChange={handleMonthSliderChange} width="97%" mx="auto" data-sentry-element="RangeSlider" data-sentry-source-file="AvailabilityFilter.tsx">
          <RangeSliderTrack data-sentry-element="RangeSliderTrack" data-sentry-source-file="AvailabilityFilter.tsx">
            <RangeSliderFilledTrack data-sentry-element="RangeSliderFilledTrack" data-sentry-source-file="AvailabilityFilter.tsx" />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} data-sentry-element="RangeSliderThumb" data-sentry-source-file="AvailabilityFilter.tsx" />
          <RangeSliderThumb index={1} data-sentry-element="RangeSliderThumb" data-sentry-source-file="AvailabilityFilter.tsx" />
        </RangeSlider>
        <HStack width="100%" justifyContent="space-between" data-sentry-element="HStack" data-sentry-source-file="AvailabilityFilter.tsx">
          <Text fontSize="medium" data-sentry-element="Text" data-sentry-source-file="AvailabilityFilter.tsx">
            {new Intl.DateTimeFormat("en-US", formatOptions).format(monthRangeStart)}
          </Text>
          <Text fontSize="medium" data-sentry-element="Text" data-sentry-source-file="AvailabilityFilter.tsx">
            {new Intl.DateTimeFormat("en-US", formatOptions).format(monthRangeEnd)}
          </Text>
        </HStack>
      </>;
  };
  const renderDatesFilterControl = () => {
    return <Stack width="100%" direction="row" alignItems="center" data-sentry-element="Stack" data-sentry-component="renderDatesFilterControl" data-sentry-source-file="AvailabilityFilter.tsx">
        <InputGroup data-sentry-element="InputGroup" data-sentry-source-file="AvailabilityFilter.tsx">
          <Input variant="date" isInvalid={startError} value={localState?.start ? formatInputDate(localState.start) : ""} data-testid="availability-start-date" name="availability-start-date" max={editedFilters[FiltersType.AVAILABILITY]?.end ? formatInputDate(editedFilters[FiltersType.AVAILABILITY].end) : ""} min="2020-01-01" type="date" onChange={e => onChange("start", e.target.value)} data-sentry-element="Input" data-sentry-source-file="AvailabilityFilter.tsx" />
        </InputGroup>
        <Box data-sentry-element="Box" data-sentry-source-file="AvailabilityFilter.tsx">-</Box>
        <InputGroup data-sentry-element="InputGroup" data-sentry-source-file="AvailabilityFilter.tsx">
          <Input variant="date" isInvalid={endError} value={localState?.end ? formatInputDate(localState.end) : ""} data-testid="availability-end-date" name="availability-end-date" min={editedFilters[FiltersType.AVAILABILITY]?.start ? formatInputDate(editedFilters[FiltersType.AVAILABILITY].start) : ""} type="date" onChange={e => onChange("end", e.target.value)} data-sentry-element="Input" data-sentry-source-file="AvailabilityFilter.tsx" />
        </InputGroup>
      </Stack>;
  };
  return <VStack alignItems="flex-start" data-sentry-element="VStack" data-sentry-component="AvailabilityFilter" data-sentry-source-file="AvailabilityFilter.tsx">
      <HStack data-sentry-element="HStack" data-sentry-source-file="AvailabilityFilter.tsx">
        <Text fontSize="medium" data-sentry-element="Text" data-sentry-source-file="AvailabilityFilter.tsx">Dates</Text>
        <Switch isChecked={filterByMonths} onChange={handleDateMonthToggle} data-testid="availability-date-month-toggle" data-sentry-element="Switch" data-sentry-source-file="AvailabilityFilter.tsx" />
        <Text fontSize="medium" data-sentry-element="Text" data-sentry-source-file="AvailabilityFilter.tsx">Months</Text>
      </HStack>
      {renderFilterControl()}
    </VStack>;
};
export default AvailabilityFilter;