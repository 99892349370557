import { Checkbox, InputGroup } from "@chakra-ui/react";
import { useMemo } from "react";
import { useFilter } from "@/filters/providers/FiltersProvider";
import { FiltersType } from "@/filters/types";
const CollegeCreditFilter = () => {
  const {
    editedFilters,
    onToggle
  } = useFilter();
  const onChange = () => {
    onToggle(FiltersType.COLLEGE_CREDIT, true);
  };
  const isChecked = useMemo(() => {
    return Array.isArray(editedFilters[FiltersType.COLLEGE_CREDIT]) && editedFilters[FiltersType.COLLEGE_CREDIT].includes(true);
  }, [editedFilters[FiltersType.COLLEGE_CREDIT]]);
  return <InputGroup mb={2} data-sentry-element="InputGroup" data-sentry-component="CollegeCreditFilter" data-sentry-source-file="CollegeCreditFilter.tsx">
      <Checkbox iconSize="24px" variant="snowday" isChecked={isChecked} onChange={onChange} data-sentry-element="Checkbox" data-sentry-source-file="CollegeCreditFilter.tsx" />
    </InputGroup>;
};
export default CollegeCreditFilter;